import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import style from './TagsDetail.module.scss'
import blogStyle from '../blog/BlogPage.module.scss'
import get from 'lodash/get'
import withIntl from '../../i18n/withIntl'
import { injectIntl } from 'react-intl'
import Helmet from 'react-helmet'
import kebabCase from 'lodash/kebabCase'
import DefaultLayout from '../default/defaultLayout'

class TagsDetail extends Component {
  static propTypes = {
    pageContext: PropTypes.object,
    data: PropTypes.object,
    intl: PropTypes.object,
  }

  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { intl } = this.props
    const { tag } = this.props.pageContext
    const posts = this.props.data.allMarkdownRemark.edges.filter(post => post.node.fields.slug.includes(intl.locale))
    return (
      <DefaultLayout>
        <div className={style.tags}>
          <div className={style.innerContainer}>
            <Helmet
              meta={[{ charset: 'utf-8' }]}
              title={`${intl.messages.tagsPage.postsWithTag} ${intl.locale === 'en' ? `"${tag}"` : `“${tag}”`} | ${siteTitle}`}
            />
            <h1>{`${intl.messages.tagsPage.postsWithTag} ${intl.locale === 'en' ? `"${tag}"` : `“${tag}”`}`}</h1>
            <div className={blogStyle.blogList} ref={this.blogList}>
              {posts.map(({ node }) => {
                const title = get(node, 'frontmatter.title') || node.fields.slug
                return (
                  <div key={node.fields.slug} className={blogStyle.blogItem}>
                    <Link to={`/${intl.locale}${node.fields.slug}`}></Link>
                    <h4>{title}</h4>
                    <small>{node.frontmatter.date}</small>
                    {node.frontmatter.tags && node.frontmatter.tags[0] && (
                      <div className={blogStyle.blogTags}>
                        {node.frontmatter.tags.map((tag, index) => {
                          return (
                            <Link key={index} to={`/${intl.locale}/tags/${kebabCase(tag)}/`}>{tag}</Link>
                          )
                        })}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className={blogStyle.blogMore}>
              <Link className={blogStyle.blogMoreBtn} to={`/${intl.locale}/tags/`}>{intl.messages.tagsPage.allTags}</Link>
            </div>
          </div>
        </div>
      </DefaultLayout>
    )
  }
}

export default withIntl(injectIntl(TagsDetail))

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD.MM.YYYY")
            tags
          }
        }
      }
    }
  }
`